import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import './livequizpage.css';
import { getUserDetails } from '../../actions/userActions';


function LiveQuizPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Redux states
    const access_token = useSelector((state) => state.userLogin?.userInfo?.access);
    const userDetails = useSelector((state) => state.userDetails);
    const user = userDetails?.user; // Access user directly

    // Local states
    const [firstAssessmentId, setFirstAssessmentId] = useState(null);
    const [quizDetails, setQuizDetails] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [totalAnswers, setTotalAnswers] = useState(0);
    const [score, setScore] = useState(0);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [countdown, setCountdown] = useState(null); // Countdown timer
    const [selectedChoice, setSelectedChoice] = useState(null);
    const [timerRunning, setTimerRunning] = useState(false);

    const startCountdown = () => {
        return new Promise((resolve) => {
            let timer = 3;
            const finishMessage = "BEGIN!";
            setCountdown(timer);
    
            const interval = setInterval(() => {
                timer -= 1;
                setCountdown(timer > 0 ? timer : finishMessage);
    
                if (timer <= 0) {
                    clearInterval(interval);
                    console.log("Countdown finished!");
    
                    // Show "BEGIN!" for 1 second before clearing
                    setTimeout(() => {
                        setCountdown(null);  // Clear after delay
                        resolve();  // Notify that the countdown is complete after clearing
                    }, 1000);
                }
            }, 1000);
        });
    };
    
    

    // Ensure `userDetails` is always fetched
    useEffect(() => {
        if (!user) {
            console.log('Fetching user details...');
            dispatch(getUserDetails());
        }
    }, [dispatch, user]);

    // Fetch assessments and set the firstAssessmentId
    useEffect(() => {
        const fetchAssessments = async () => {
            try {
                const response = await axios.get('https://api.andrewslearning.com/api/assessments/');
                const data = response.data;

                if (data.length === 0) {
                    setError('No assessments found.');
                    return;
                }

                setFirstAssessmentId(data[0].id);
            } catch (err) {
                setError('Error fetching assessments.');
            } finally {
                setLoading(false);
            }
        };

        fetchAssessments();
    }, []);

    // Fetch quiz details when firstAssessmentId is set
    useEffect(() => {
        const fetchQuizDetails = async () => {
            if (!firstAssessmentId) return;

            try {
                setLoading(true);
                const response = await axios.get(
                    `https://api.andrewslearning.com/api/assessments/${firstAssessmentId}`,
                    {
                        headers: { Authorization: `Bearer ${access_token}` },
                    }
                );
                setQuizDetails(response.data);
            } catch (err) {
                setError('Error fetching quiz details.');
            } finally {
                setLoading(false);
            }
        };

        fetchQuizDetails();
    }, [firstAssessmentId, access_token]);

    // WebSocket setup
    useEffect(() => {
        if (!firstAssessmentId || !user?.id) return;

        const newSocket = new WebSocket(`wss://api.andrewslearning.com/wschat/livequiz/${firstAssessmentId}/`);
        setSocket(newSocket);

        newSocket.onopen = () => {
            console.log('WebSocket connection established');
            newSocket.send(
                JSON.stringify({
                    type: 'join_quiz',
                    profile_id: user.id,
                })
            );
        };

        newSocket.onmessage = (event) => {
            const messageData = JSON.parse(event.data);

            switch (messageData.type) {
                case 'welcome_message':
                    console.log(messageData.message);
                    break;

                case "user_list_update":
                    const message = messageData.message
                    if (Array.isArray(messageData.message)) {
                        console.log("Received valid user list:", messageData.message);
                        setConnectedUsers(messageData.message);
                    } else {
                        console.error("Invalid user list received:", messageData.message);
                    }
                    break;
                    case 'user_entry':
                    console.log('User joined:', messageData);
                    setConnectedUsers((prev) => {
                        // Avoid duplicates
                        if (!prev.includes(messageData.message)) {
                            return [...prev, messageData.message];
                        }
                        return prev;
                    });
                    console.log(connectedUsers);
                    break;

                case 'user_exit':
                    setConnectedUsers((prev) =>
                        prev.filter((username) => username !== messageData.username)
                    );
                    break;
                case "next_question":
                    console.log("Next Question:", messageData.question);
                    setCurrentQuestion(messageData.question);
                    break;
        
                case "quiz_completed":
                    console.log("Quiz Completed!");
                    break;
                

                case 'questions_are_prepared':
                    console.log(messageData.questions);
                    // Display next question after timer
                    setCurrentQuestion({
                        id: messageData.question_id,
                        text: messageData.questions[0].text,
                        choices: messageData.questions[0].choices,
                    });
            
                    console.log("Question is now displayed after timer.");
                    
                    
                    break;
        
                    case "start_timer":
                        console.log("Timer officially started!");
                    
                        // Ensure countdown completes before sending WebSocket and showing question
                        startCountdown().then(() => {
                            setTimerRunning(false); 
                    
                            // Send 'start_quiz' only after countdown ends
                            newSocket.send(
                                JSON.stringify({
                                    type: "start_quiz",
                                })
                            );
                    
                            
                        });
                        break;
        
                case "next_question":
                    console.log("Next Question:", messageData.question);
        
                    // Ensure timer runs before showing the next question
                    setTimerRunning(true); 
                    setCurrentQuestion(null); 
        
                    startCountdown().then(() => {
                        setTimerRunning(false);  // Timer finished, show the next question
                        setCurrentQuestion(messageData.question);
                    });
                    break;
                
                case 'quiz_update':
                    setTotalAnswers(messageData.total_answers || 0);
                    setScore((prev) => prev + (messageData.score || 0));
                    break;

                default:
                    console.warn('Unhandled WebSocket message type:', messageData.type);
            }
        };

        newSocket.onclose = () => console.log('WebSocket connection closed');
        newSocket.onerror = (error) => console.error('WebSocket error:', error);

        return () => {
            newSocket.close();
        };
    }, [firstAssessmentId, user]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="outer-quiz-container">
        <div className="quiz-container">
            
            {/* Countdown Animation */}
            {countdown !== null && (
                <div className="countdown-overlay">
                    <h1 className="countdown-text animate-countdown">{countdown}</h1>
                </div>
            )}
    
            {/* Quiz Title */}
            <h4 className="quiz-heading">{quizDetails?.name}</h4>
    
            {/* Question Display */}
            {!timerRunning && currentQuestion ? (
                <div className="quiz-question-container">
                    <div className="quiz-question">{currentQuestion.text}</div>
                    <ul className="choices-list">
                        {currentQuestion.choices.map((choice) => (
                            <li
                                key={choice.id}
                                className={`choice-item ${selectedChoice?.id === choice.id ? 'selected' : ''}`}
                                onClick={() => setSelectedChoice(choice)}
                            >
                                {choice.text}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <button 
                    className="start-game-button" 
                    onClick={async () => {
                        socket?.send(JSON.stringify({ type: "start_timer" }));  // Send WebSocket message
                        console.log("WebSocket message sent after countdown.");
                    }}
                >
                    Start Game
                </button>
            )}
    
            {/* Connected Users Display */}
            <div className="connected-users-container">
                <h5>Players in the Game</h5>
                <div className="user-cards">
                    {connectedUsers.map((username, index) => (
                        <div className="user-card" key={index}>
                            <p className="user-card-username">{username}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    );
}

export default LiveQuizPage;
